import React from 'react';
import styles from './Banner.module.scss';
import { TypeAnimation } from 'react-type-animation';
import clsx from 'clsx';
const Banner = () => {
  return (
    <>
      {' '}
      <div className={clsx(styles.wrap)}>
        {' '}
        <div className={clsx(styles.titleWrap)}>
          {' '}
          <h1 className={clsx(styles.title)}>Малинка бот</h1>
          <div className={clsx(styles.subtitle)}>
            {' '}
            <TypeAnimation sequence={['телеграм бот доната', 3000, 'API подключение', 3000]} speed={30} repeat={Infinity} />
          </div>
        </div>
        <div className={clsx(styles.btnList)}>
          <a href="#" className={clsx(styles.btn)}>
            @malinka_bot
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="diagonal-right-up-arrow">
              <g data-name="Layer 2">
                <path d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1 1 1 0 0 0 1-1z" data-name="diagonal-arrow-right-up"></path>
              </g>
            </svg>
          </a>
          <a href="#" className={clsx(styles.btn)}>
            @malinka_team
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="diagonal-right-up-arrow">
              <g data-name="Layer 2">
                <path d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1 1 1 0 0 0 1-1z" data-name="diagonal-arrow-right-up"></path>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default Banner;
