import React, { useEffect } from 'react';
import Space from '../../../components/Space/Space';
import { TypeAnimation } from 'react-type-animation';
import Banner from '../../../components/Banner/Banner';
import Header from '../../../components/Header/Header';
import Desc from '../../../components/Desc/Desc';
import Footer from '../../../components/Footer/Footer';

const HomePage = () => {
  return (
    <>
      <div className="container">
        <Space />
        <div className="inner">
          <Header />
          <Banner />
          <Desc />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HomePage;
