import { Navigate, useRoutes } from 'react-router';
import HomePage from './pages/site/HomePage/HomePage';

function App() {
  let routes = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    },
  ]);

  return routes;
}

export default App;
