import React from 'react';
import styles from './Footer.module.scss';
import clsx from 'clsx';
const Footer = () => {
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <div className={clsx(styles.left)}>Copyright © 2024 FOMO Network | All Rights Reserved</div>
        <div className={clsx(styles.right)}>
          <a href="#" className={clsx(styles.link)}>
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
