import React from 'react';
import styles from './Desc.module.scss';
import clsx from 'clsx';
const Desc = () => {
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <div className={clsx(styles.left)}>
          {' '}
          <div className={clsx(styles.caption)}>FOMO CHAIN</div>
          <div className={clsx(styles.title)}>Explore the Network</div>
          <div className={clsx(styles.desc)}>Built on Cosmos SDK to provide interoperability between most existing chains and evm support via WASM. Aims to decentralize social media, scale storage-heavy applications and aid adoption to billions of users. </div>
          <div className={clsx(styles.listTitle)}>Network Benefits:</div>
          <div className={clsx(styles.list)}>
            <div className={clsx(styles.item)}>
              <svg aria-hidden="true" class="e-font-icon-svg e-fas-plus" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
              </svg>
              EVM Compatible
            </div>
            <div className={clsx(styles.item)}>
              {' '}
              <svg aria-hidden="true" class="e-font-icon-svg e-fas-plus" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
              </svg>
              Low Transaction Costs
            </div>
            <div className={clsx(styles.item)}>
              {' '}
              <svg aria-hidden="true" class="e-font-icon-svg e-fas-plus" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
              </svg>
              10,000 Transactions per second
            </div>
            <div className={clsx(styles.item)}>
              {' '}
              <svg aria-hidden="true" class="e-font-icon-svg e-fas-plus" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
              </svg>
              Fast Finality
            </div>
          </div>
          <a href="#" className={clsx(styles.btn)}>
            Testnet Live{' '}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="diagonal-right-up-arrow">
              <g data-name="Layer 2">
                <path d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1 1 1 0 0 0 1-1z" data-name="diagonal-arrow-right-up"></path>
              </g>
            </svg>
          </a>
        </div>
        <div className={clsx(styles.right)}>
          <img src="/img/logo-icon.jpg" alt="" className={clsx(styles.image)} />
        </div>
      </div>
    </>
  );
};

export default Desc;
