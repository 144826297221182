import React from 'react';
import styles from './Header.module.scss';
import clsx from 'clsx';
const Header = () => {
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <div className="container">
          <img src="/img/logo.png" alt="" className={clsx(styles.logo)} />
        </div>
      </div>
    </>
  );
};

export default Header;
