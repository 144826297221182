import { createSlice } from '@reduxjs/toolkit';
import { initStateCreateOrder, reducerCreateOrder } from '../actions/order/createOrder';
import { initStateGetOrderList, reducerGetOrderList } from '../actions/order/getOrderList';
import { initStateGetOrderSingle, reducerGetOrderSingle } from '../actions/order/getOrderSingle';
import { initStateChangeTypePaymentOrder, reducerChangeTypePaymentOrder } from '../actions/order/changeTypePaymentOrder';
import { initStateGetOrderListAdmin, reducerGetOrderListAdmin } from '../actions/order/getOrderListAdmin';
import { initStateUpdateOrder, reducerUpdateOrder } from '../actions/order/updateOrder';
import { initStateCreatePayment, reducerCreatePayment } from '../actions/order/createPayment';
import { initStateGetSaveGameInputs, reducerGetSaveGameInputs } from '../actions/order/getSaveGameInputs';
import { initStateRemoveSaveGameInput, reducerRemoveSaveGameInput } from '../actions/order/removeSaveGameInput';
import { initStateCreateTopUp, reducerCreateTopUp } from '../actions/order/createTopUp';
export const initialState = {
  ...initStateCreateOrder,
  ...initStateGetOrderList,
  ...initStateGetOrderSingle,
  ...initStateChangeTypePaymentOrder,
  ...initStateGetOrderListAdmin,
  ...initStateUpdateOrder,
  ...initStateCreatePayment,
  ...initStateGetSaveGameInputs,
  ...initStateRemoveSaveGameInput,
  ...initStateCreateTopUp,

  editOrderId: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetCreateOrder(state) {
      state.createOrder = initStateCreateOrder.createOrder;
    },
    resetCreatePayment(state) {
      state.createPayment = initStateCreatePayment.createPayment;
    },
    resetGetSingleOrder(state) {
      state.getOrderSingle = initStateGetOrderSingle.getOrderSingle;
    },
    resetUpdateOrder(state) {
      state.updateOrder = initStateUpdateOrder.updateOrder;
    },
    resetRemoveSaveGameInput(state) {
      state.removeSaveGameInput = initStateRemoveSaveGameInput.removeSaveGameInput;
    },
    resetGetSaveGameInputs(state) {
      state.getSaveGameInputs = initStateGetSaveGameInputs.getSaveGameInputs;
    },
    setEditOrderId(state, action) {
      state.editOrderId = action.payload;
    },
  },
  extraReducers: {
    ...reducerCreateOrder,
    ...reducerGetOrderList,
    ...reducerGetOrderSingle,
    ...reducerChangeTypePaymentOrder,
    ...reducerGetOrderListAdmin,
    ...reducerUpdateOrder,
    ...reducerCreatePayment,
    ...reducerGetSaveGameInputs,
    ...reducerRemoveSaveGameInput,
    ...reducerCreateTopUp,
  },
});
export const { resetCreateOrder, setEditOrderId, resetUpdateOrder, resetRemoveSaveGameInput, resetGetSingleOrder, resetCreatePayment, resetGetSaveGameInputs } = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
