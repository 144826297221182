import { createSlice } from '@reduxjs/toolkit';
import { initStateGetCountryList, reducerGetCountryList } from '../actions/app/getCountryList';
import { initStateGetFilterGameList, reducerGetFilterGameList } from '../actions/app/getFilterGameList';
import { initStateGetTypePaymentList, reducerGetTypePaymentList } from '../actions/app/getTypePayment';

export const initialState = {
  modalTask: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setModalTask(state, action) {
      state.modalTask = action.payload;
    },
  },
  extraReducers: {},
});
export const { setModalTask } = appSlice.actions;
export const appReducer = appSlice.reducer;
